import React from 'react';
import '../styling/about.css';

function About() {
  return (
    <div id="about" className="about-container">
      <div className="about-content">
        <h1>Hi, I'm <span>Samuel</span></h1> 
      </div>
      <div className='about-content-1'>
        <p style={{textAlign: "left"}}>
          As a <span style={{backgroundColor: "#D99329"}}>versatile software engineer</span> with a knack for business and data analysis, 
          I excel in creating intuitive <span>user interfaces</span>, dynamic <span>data visualizations</span>, 
          and comprehensive <span>full-stack applications</span>.
        </p>
        <p style={{textAlign: "right"}}>
          My expertise spans <span>process automation</span>, <span>project management</span>, 
          and the <span>development of robust solutions</span> that drive <span>operational efficiency</span>. 
          Eager to bring my diverse skill set to your innovative projects, 
          I am committed to delivering exceptional results and driving business success.
        </p>
      </div>

      <div className="skills-container">
        <div className="skills-wrapper">
          <div className='skill-grid-bgh'></div>
          <div className="skills-grid">
            <div className="skill-box">
              <ul>
                <li><span>JavaScript</span></li>
                <li>Python</li>
                <li><span>TypeScript</span></li>
                <li>ReactJS</li>
                <li><span>HTML</span></li>
                <li>APIs</li>
                <li><span>CSS</span></li>
                <li>XML</li>
                <li><span>Git</span></li>
                <li>SQL</li>
                <li><span>Java</span></li>
                <li>C#</li>
              </ul>
            </div>
          </div>
          <div className='skill-grid-bgv'></div>
        </div>
      </div>
    </div>
  );
}

export default About;