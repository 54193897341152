import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import '../styling/projects.css';

const projects = [
  {
    id: 1,
    title: 'itsLeri Studios',
    imageUrl: '/assets/itsLeriStudios.gif',
    tags: ['Web Application'],
    projectUrl: 'https://www.itsLeriStudios.com',
    githubUrl: '',
    description: 'itsLeri Studios is a multimedia production studio which specializes in creating immersive multimedia experiences, from still and motion images to podcasts and audio narratives, all with a focus on storytelling that resonates and inspires.',
    technology: ['ReactJS', 'CSS', 'JavaScript'],
  },
  // {
  //   id: 2,
  //   title: 'WaveSync',
  //   imageUrl: '/assets/wavsync.gif',
  //   tags: ['Mobile Application'],
  //   projectUrl: '',
  //   githubUrl: 'https://github.com/samueloluwadoromi/wavesync-music-player',
  //   description: 'WaveSync Music Player is a feature-rich mobile application developed using React Native. Designed for music enthusiasts, it offers a seamless and intuitive music listening experience on both iOS and Android devices. Key features include high-quality audio playback, detailed track information, and robust playback controls. Users can easily navigate through tracks, control playback with play, pause, next, and previous buttons, and use the slider for precise track positioning. The app also supports repeat and shuffle modes, providing an enhanced listening experience. With a responsive design, WaveSync Music Player offers both a compact and full-screen view for an immersive user experience. Built with Expo AV for audio playback and TypeScript for improved code quality, WaveSync Music Player exemplifies modern mobile application development.',
  //   technology: ['React Native', 'Expo AV', 'TypeScript', 'React Native Community Slider']
  // },
];

const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);

  const openProjectModal = (project) => {
    setSelectedProject(project);
  };

  const closeProjectModal = () => {
    setSelectedProject(null);
  };

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const uniqueTags = [...new Set(projects.flatMap((project) => project.tags))];

  const filteredProjects = selectedTags.length
    ? projects.filter((project) => project.tags.some((tag) => selectedTags.includes(tag)))
    : projects;

  return (
    <div id='projects' className='projects-container'>
      <h2>Projects</h2>
      <div className='tag-filter'>
        {uniqueTags.map((tag) => (
          <button
            key={tag}
            className={`tag-button ${selectedTags.includes(tag) ? 'selected' : ''}`}
            onClick={() => handleTagClick(tag)}
          >
            {tag}
          </button>
        ))}
      </div>
      <div className="project-grid">
        {filteredProjects.map((project) => (
          <div key={project.id} className='project-item'>
            <img
              src={project.imageUrl}
              alt={project.title}
              onClick={() => openProjectModal(project)}
            />
          </div>
        ))}
      </div>
      {selectedProject && (
        <div className="project-modal">
          <div className="modal-content">
            <span className="close" onClick={closeProjectModal}>&times;</span>
            <h3>{selectedProject.title}</h3>
            <img
              src={selectedProject.imageUrl}
              alt={selectedProject.title}
            />
            <p>{selectedProject.description}</p>
            <div className="technologies">
              {selectedProject.technology.map((tech, index) => (
                <span key={index} className="technology">{tech}</span>
              ))}
            </div>
            <div className="external-links">
              {selectedProject.projectUrl && (
                <div className="external-link">
                  <h3>
                    <FontAwesomeIcon icon={faGlobe} /> Website
                  </h3>
                  <a href={selectedProject.projectUrl} target="_blank" rel="noopener noreferrer">
                    {selectedProject.projectUrl}
                  </a>
                </div>
              )}
              {selectedProject.githubUrl && (
                <div className="external-link">
                  <h3>
                    <FontAwesomeIcon icon={faGithub} /> GitHub
                  </h3>
                  <a href={selectedProject.githubUrl} target="_blank" rel="noopener noreferrer">
                    {selectedProject.githubUrl}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Projects;
