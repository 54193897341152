import React from 'react';
import '../styling/contact.css';

const Contact = () => {
    const handleEmailClick = () => {
        window.location.href = 'mailto:samueloluwadoromi@gmail.com';
    };

    return (
        <div id='contact' className="contact-container">
            <button onClick={handleEmailClick} className="email-button">
                Send Email
            </button>
        </div>
    );
};

export default Contact;
