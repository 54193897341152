import React from 'react';
// import  { useState, useEffect, useRef } from 'react';
import '../styling/home.css';

function Home() {
  return (
    <div id='home' className='home'> 
      <div className='home-container'>
          <div className='home-container-1'>
            <img src='/assets/samueloluwadoromi.png' alt='Samuel Oluwadoromi'/>
            <h1 className='home-container-header'>
              <span>"</span>Crafting seamless <span style={{backgroundColor: "#D99329", fontWeight: "bold"}}>user experiences</span> and robust <span style={{backgroundColor: "#D99329", fontWeight: "bold"}}>backend solutions</span><span>"</span>
            </h1>
          </div>
          <div className='home-container-2'>
            <img src='/assets/samuel-oluwadoromi-image.png' alt='Samuel Oluwadoromi'/>
          </div>
      </div>  
    </div>
  );
}

export default Home;
